html, body, #root, #root>div {
    height: 100%
  }

.loader-container {
    width: 100%;
    height: 100px;
    align-items: center;
    z-index: 1000;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.loader-item {
  height: 100%;
  background-color: white;
  width: 100%;
  margin-top: 175px;
  padding-top: 100px;
}